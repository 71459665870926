'use client';

import Flag from '@components/Flag';
import { Select } from '@components/inputs';
import LinkOutlined from '@components/LinkOutlined';
import { useMarket } from '@context/marketContext';
import { Button, Input } from '@ngg/components';
import { CheckIcon } from '@ngg/icons';
import type { Form, KeyValue, Maybe } from '@server/gql/graphql';
import { useRouter } from 'next/navigation';
import { useCallback, useMemo } from 'react';
import { useFormState, useFormStatus } from 'react-dom';
import type { Dictionary } from 'types';

import { createNewCustomer } from '@/actions/createNewCustomer';
import { marketConfigurations } from '@/config/market-configurations';
import { cn } from '@/lib/utils';
import { createDictionary } from '@/utils/common';

import { NewCustomerHeader } from './newCustomerHeader';

const SelectIcon = ({ id }: { id: string }) => (
    <Flag code={id} className="h-full w-full rounded-full object-cover" />
);

const formattedMarkets = Object.values(marketConfigurations)
    .filter((m) => m.code !== 'se' && m.code !== 'no')
    .map((m) => ({
        id: m.code,
        value: m.code,
        label: m.label,
    }));

type NewCustomerFormProps = {
    dictionary: Dictionary;
    onClose?: () => void;
    classNames?: {
        instagramButton?: string;
        emailInput?: string;
        submitButton?: string;
        selectWrapper?: string;
        selectOption?: string;
    };
    onlyForm?: boolean;
};

export default function NewCustomerForm({
    classNames,
    dictionary,
    onClose,
    onlyForm = false,
}: NewCustomerFormProps) {
    const { push } = useRouter();
    const { market } = useMarket().state;
    const showCountrySelect = useMemo(() => {
        return market.code !== 'no' && market.code !== 'se';
    }, [market.code]);

    const createNewCustomerWithContext = createNewCustomer.bind(
        null,
        showCountrySelect,
        market.code,
    );
    const [state, formAction] = useFormState(createNewCustomerWithContext, {});
    const { pending } = useFormStatus();

    const preSelectedOption = formattedMarkets.find(
        (m) => m.id === market.code,
    );

    const onCloseForm = useCallback(() => {
        if (onClose) {
            onClose();
            return;
        }
        push('/');
    }, [push, onClose]);

    if (state.success !== undefined) {
        return (
            <div>
                <h2 className="text-lg font-bold">
                    {dictionary?.newCustomerFormSuccessHeading}
                </h2>
                <p className="mt-4 text-sm">
                    {dictionary?.newCustomerFormSuccessText}
                </p>
                <Button
                    onClick={onCloseForm}
                    fullWidth
                    className="btn-outlined mt-4 bg-white text-black hover:border-white">
                    {dictionary?.newCustomerFormSuccessButtonText}
                </Button>
            </div>
        );
    }

    return (
        <form
            action={formAction}
            className={cn(
                'grid gap-6 md:grid-cols-2 md:gap-12',
                onlyForm && ' grid w-full grid-cols-1 md:grid-cols-1',
            )}>
            {!onlyForm && (
                <NewCustomerHeader dictionary={dictionary} onClose={onClose} />
            )}
            <div
                className={cn(
                    'flex max-w-72 flex-col',
                    onlyForm && 'w-full max-w-full',
                )}>
                <label className="mb-2 block text-sm font-bold">
                    1. {dictionary?.newCustomerFormInstagramButtonLabel}
                </label>
                <LinkOutlined
                    href={
                        dictionary?.newCustomerFormInstagramUrl ||
                        'https://www.instagram.com/nordiskagalleriet/'
                    }
                    // #TODO don't use hex colors this is temporary and will be fixed in the next with a figma token system
                    className={cn(
                        'group',
                        'bg-black text-white',
                        'visited:border-[#25C50B] visited:bg-[#25C50B] hover:visited:border-black',
                    )}
                    target="_blank"
                    startIcon={
                        <span
                            className={cn(
                                'flex items-center',
                                '-ml-[14px] rounded-full',
                                'bg-black group-visited:bg-white group-hover:bg-white',
                            )}>
                            <CheckIcon
                                className={cn(
                                    'pt-[1px]',
                                    'text-black',
                                    'group-hover:text-white',
                                    'group-visited:text-black group-hover:group-visited:text-black',
                                )}
                            />
                        </span>
                    }>
                    {dictionary?.newCustomerFormInstagramButtonText}
                </LinkOutlined>
                <div className="space-y-2">
                    <Input
                        id="email"
                        type="email"
                        name="email"
                        placeholder={
                            dictionary?.newCustomerFormEmailInputPlaceholder
                        }
                        required
                        fullWidth
                        label={`2. ${dictionary?.newCustomerFormEmailInputLabel}`}
                        classNames={{
                            wrapper: 'mt-4',
                            label: 'text-sm font-bold mb-2',
                            input: cn(
                                'h-10 w-full border-grey-150 px-4 py-0 text-base placeholder:text-sm',
                                classNames?.emailInput,
                            ),
                        }}
                    />
                    {showCountrySelect && (
                        <Select
                            name="countryCode"
                            placeholder={
                                dictionary?.newCustomerFormCountrySelectPlaceholder
                            }
                            options={formattedMarkets}
                            required
                            labelIcon={SelectIcon}
                            optionIcon={SelectIcon}
                            preSelectedOption={preSelectedOption}
                            classNames={{
                                wrapper: classNames?.selectWrapper,
                                option: classNames?.selectOption,
                                button: onlyForm ? 'border-grey-150' : '',
                            }}
                        />
                    )}

                    {state.error !== undefined ? (
                        <div className="mt-4 text-sm text-error">
                            <p className="font-medium">
                                {dictionary?.newCustomerFormErrorHeading}
                            </p>
                            <p>{state.error.message}</p>
                        </div>
                    ) : null}

                    <Button
                        type="submit"
                        fullWidth
                        className={cn('btn-primary', classNames?.submitButton)}
                        isLoading={pending}
                        loadingClassName="text-inherit">
                        {dictionary?.formSendButtonTextNewCustomer}
                    </Button>
                </div>
            </div>
        </form>
    );
}

export function NewCustomerPopup({ form }: { form: Maybe<Form> | undefined }) {
    const formDictionary = createDictionary(
        form?.dictionaryCollection?.items as KeyValue[],
    );
    return (
        <div className="mx-auto max-w-screen-md bg-black px-6 py-8 text-white md:px-8 md:py-10">
            <NewCustomerForm
                dictionary={formDictionary}
                classNames={{
                    instagramButton:
                        'border-white group hover:bg-white hover:border-white hover:text-black text-white',
                    submitButton:
                        'btn-inverted hover:bg-black hover:text-white hover:border-white',
                    emailInput: 'placeholder:text-white',
                    selectWrapper: 'bg-black text-white border',
                    selectOption: 'ui-active:bg-gray-100/10',
                }}
            />
        </div>
    );
}
